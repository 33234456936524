import React, { FC } from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import MediaQueries from '@components/common/MediaQueries'

import { TagGeneric } from '@components/common/Eulerian'
import { TagPageCategory } from '@components/common/GoogleTagManager'
import SigninModul from '@components/common/SigninModul'
import SideMedia from '@components/ui/SideMedia'

import s from './LoginView.module.css'

const LoginView: FC<{ referer: string }> = ({ referer }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  const metaTitle = 'Login Smallable'
  const metaDescription = t('layout:common.metaDescription')
  const canonicalUrl = `https://www.smallable.com/${locale}/login`
  const alternateUrl = [
    {
      hrefLang: 'de',
      href: 'https://www.smallable.com/de/login',
    },
    {
      hrefLang: 'en',
      href: 'https://www.smallable.com/en/login',
    },
    {
      hrefLang: 'es',
      href: 'https://www.smallable.com/es/login',
    },
    {
      hrefLang: 'fr',
      href: 'https://www.smallable.com/fr/login',
    },
    {
      hrefLang: 'it',
      href: 'https://www.smallable.com/it/login',
    },
  ]

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        noindex
        nofollow
        canonical={canonicalUrl}
        languageAlternates={alternateUrl}
        openGraph={{
          type: 'website',
          title: metaTitle,
          description: metaDescription,
          url: canonicalUrl,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_ASSETS_URL}/bundles/smallablefront/css/img/logo.png`,
              width: 600,
              height: 315,
            },
          ],
        }}
        twitter={{
          cardType: 'summary',
          site: '@smallable',
        }}
      />
      <TagGeneric urlp="/login" />
      <TagPageCategory pageCategory="login" />
      <div className={s.container}>
        <MediaQueries hidden={['xs', 'sm']}>
          <SideMedia url="/img/store-entrance-light.jpg" />
        </MediaQueries>
        <SigninModul className={s.account} referer={referer} />
      </div>
    </>
  )
}

export default LoginView
