
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from 'next/head'

import { LayoutLite, NotFound } from '@components/common'
import { LoginView } from '@components/login'
import { TagGeneric } from '@components/common/Eulerian'
import { TagPageCategory } from '@components/common/GoogleTagManager'

import { fetchSession } from '@mw/session'
import WithCookieLocaleRedirect from '@hoc/WithCookieLocaleRedirect'

import type { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { forwardHeaders } from '@mw/headers'

const __getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req, res } = context

  let layout = null,
    session = null,
    setCookie = null,
    error = null
  try {
    const headers = forwardHeaders(req, context)

    const sessionData = await fetchSession({
      headers,
      locale: locale as string,
    })
    const { cookies, ...restSession } = sessionData
    session = restSession?.data
    setCookie = cookies
    layout = { ...layout, session }

    if (session?.logged) {
      return {
        redirect: {
          permanent: false,
          destination: `/${locale}/account/`,
        },
      }
    }
  } catch (err) {
    console.error(err.message)
    error = {
      status: err.status || 500,
      message: err.message || `An error occured fetching the page /login`,
    }
    res.statusCode = error.status
  }

  if (setCookie) res.setHeader('Set-Cookie', setCookie)

  const referer = req?.headers?.referer || null

  return {
    props: {
      ...layout,
      error,
      locale,
      referer,
    },
  }
}

 const _getServerSideProps = WithCookieLocaleRedirect(__getServerSideProps)

export default function Login({
  error,
  referer,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  if (error) {
    return (
      <>
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        <TagGeneric isError />
        <TagPageCategory pageCategory="erreur" />
        <NotFound />
      </>
    )
  }

  return <LoginView referer={referer} />
}

Login.Layout = LayoutLite


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  